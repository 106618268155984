<template>

    <zw-sidebar @shown="shown" :title="$t('insurance.title.insurance')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col sm="6">
                        <zw-input-group v-model="form.number"
                                        name="number"
                                        type="text"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                    <b-col sm="6">
                        <zw-input-group v-model="form.amount"
                                        name="amount"
                                        type="number"
                                        min="0"
                                        step="0.01"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>

                    <b-col cols="6">
                        <zw-input-group v-model="form.vat"
                                        name="vat"
                                        type="number"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                                        :readonly="true"
                        ></zw-input-group>
                    </b-col>

                    <b-col cols="6">
                        <zw-file-group v-model="form.insurance"
                                       name="insurance"
                                       :label-prefix="labelPrefix"
                                       document-type="insurances"
                                       accept=".pdf"
                        ></zw-file-group>
                    </b-col>

                    <b-col cols="12">
                        <zw-input-group v-model="form.name"
                                        name="name"
                                        :label-prefix="labelPrefix"
                                        :readonly="true"
                        ></zw-input-group>
                    </b-col>
                </b-row>

                <b-row v-if="!readOnly">
                    <b-col sm="12" class="text-xs-center">
                        <b-row align-h="end">
                            <b-col sm="6" class="text-sm-right">
                                <b-button block @click="onSubmit()" variant="primary">
                                    {{ $t('common.button.save') }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-col>

                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            labelPrefix: 'insurance.label.',
            payload: {},
            callback: null,
            defaultForm: {
                vat: 0,
                position_id: null,
            },
            form: {},
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getVats']),
        ...mapGetters('Insurance', ['getInsurance']),
        shown() {
            this.defaultForm.position_id = this.payload.id
            this.readOnly = this.payload.status == 'refunded'

            this.$store.dispatch('Insurance/fetchInsurance', {
                id: this.payload.id,
            })
                .then((data) => {
                    this.form = {
                        ...JSON.parse(JSON.stringify(this.defaultForm)),
                        ...JSON.parse(JSON.stringify(this.getInsurance()))
                    }
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.$store.dispatch('Insurance/saveInsurance', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        insurance(val) {
            if (val) {
                this.form.amount = this.form.full_amount
            } else {
                this.form.amount = this.getInsurance().amount
            }
        },
    }
}
</script>